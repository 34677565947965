import React, { useEffect } from 'react';
import './contents.scss';
import { useSelector } from 'react-redux';


export default function LegalPolicy() {

    useEffect(() => {
        window.scrollTo(0, 0);
        window.onbeforeunload = function () {
            window.scrollTo(0, 0);
        };

    }, [])

    const content = useSelector(state => state.home.contents?.legalPolicy)

    return (
        <div className='content-holder'>
            <div className='banner-image' >
                <img src='./images/background-images/aboutus.jpg' alt='' />
                <h5>Legal Policy</h5>
            </div>
            <div className='content' dangerouslySetInnerHTML={{ __html: content }} />
        </div>
    )
}
