import { GET_SUBSCRIPTIONS, GET_SUBSCRIPTION_DETAILS, GET_MY_SUBSCRIPTIONS } from '../Types/types';

let intialState = {
    subscriptions: null
}

export default function subscriptionReducer(state = intialState, action) {
    switch (action.type) {
        case GET_SUBSCRIPTIONS:
            return { ...state, subscriptions: action.payload.rows };
        case GET_SUBSCRIPTION_DETAILS:
            return { ...state, subscriptionDetails: action.payload };
        case GET_MY_SUBSCRIPTIONS:
            return { ...state, mySubscriptions: action.payload };
        default:
            return state;
    }
}
