import React, { useEffect } from 'react';
import './subscriptions.scss';
import { useDispatch, useSelector } from 'react-redux';
import { getSubscriptions, getSubscriptionDetails } from '../../Redux/ActionCreators/subscriptionAction';
import { useNavigate } from 'react-router-dom';

const sub = [{ name: 'Basic', price: 9.99, description: `Access to basic features \n 1 User \n Email support \n 1GB Storage \n community Access` },
{ name: 'Standard', price: 29.99, description: `Access to basic features \n 1 User \n Email support \n 1GB Storage \n community Access` },
{ name: 'Premium', price: 49.99, description: `Access to basic features \n 1 User \n Email support \n 1GB Storage \n community Access` }
]
export default function Subscriptions() {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { subscriptions } = useSelector(state => state.subscription);

    useEffect(() => {
        dispatch(getSubscriptions());
    }, [])

    const getDetails = async (data) => {
        if (localStorage.getItem('userAuthToken')) {
            navigate('/checkout', { state: { data: data } });
        } else {
            navigate('/login');
        }
    }

    return (
        <div className='subscription-holder'>
            <h3>Subscription Plans</h3>
            <p>Choose the best plan that fits your needs.</p>
            <div className='subscription-items'>
                {
                    subscriptions?.map(el => {
                        return (
                            <div key={el.id} className='subscription-item'>
                                <p>{el.name}</p>
                                <div className='sub-price'>
                                    ₹{el.price} <span>per annum</span>
                                </div>
                                <div className='master-catlog'>{el.subscriptionMasterCatalogs?.map(el1 => el1.masterCatalog?.name).join(', ')}</div>
                                <div className='buy-btn'>
                                    <button className='form-control' onClick={() => getDetails(el)}>Buy Now</button>
                                </div>
                                <div className='hr' ></div>
                                <div className='sub-desc'>
                                    <ul>

                                        {
                                            el.description?.split('\n')?.map(el1 => {
                                                return (
                                                    <li key={el1}><img src='/svg-icons/check.svg' alt='' /> &nbsp;&nbsp;{el1}</li>
                                                )
                                            })
                                        }
                                    </ul>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}
