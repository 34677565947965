import React, { useEffect, useState } from 'react';
import './subscriptions.scss';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { getCoupons, getSubscriptionDetails, paymentProceed } from '../../Redux/ActionCreators/subscriptionAction';

export default function Checkout() {
    const [coupon, setCoupon] = useState('');
    const [couponErr, setCouponErr] = useState('');
    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();

    const { subscriptionDetails } = useSelector(state => state.subscription);
    useEffect(() => {
        dispatch(getCoupons())
        dispatch(getSubscriptionDetails(location.state.data?.id, ''));
    }, [])

    const applyCoupon = async () => {
        const res = await dispatch(getSubscriptionDetails(location.state?.data?.id, coupon));
        if (res?.type === 'GET_SUBSCRIPTION_DETAILS') {
            setCouponErr('Coupon Applied');
        }
        else if (res?.type === 'SUBSCRIPTION_ERROR') {
            setCouponErr(res.payload);
        }
    }

    const removeCoupon = async () => {
        setCoupon('');
        setCouponErr('');
        await dispatch(getSubscriptionDetails(location.state?.data?.id, ''));

    }

    const proceed = async () => {
        let obj = {
            "subscriptionId": location.state.data?.id,
            "couponCode": coupon
        }
        const res = await dispatch(paymentProceed(obj));
        if (res?.type === 'PAYMENT_PROCEED') {
            navigate('/my-subscription')
        }
    }

    const { data } = location.state
    return (
        <div className='checkout-holder'>
            <div className='row align-self-stretch'>
                <div className='col-md-7'>
                    <div className='checkout-div'>
                        <h3>Checkout</h3>
                        <div>
                            <div className='subscription-item'>
                                <p>{data?.name}</p>
                                <div className='sub-price'>
                                    ₹{data?.price} <span>per annum</span>
                                </div>
                                <div className='master-catlog'>{data?.subscriptionMasterCatalogs?.map(el1 => el1.masterCatalog?.name).join(', ')}</div>

                                <div className='hr' ></div>
                                <div className='sub-desc'>
                                    <ul>

                                        {
                                            data?.description?.split('\n')?.map(el1 => {
                                                return (
                                                    <li key={el1}><img src='/svg-icons/check.svg' alt='' /> &nbsp;&nbsp;{el1}</li>
                                                )
                                            })
                                        }
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <ul>
                            <li>○ No Commitments, Cancel anytime </li>
                            <li>○ Unlimited creative information on Food, Health & Wellness, and many others.</li>
                            <li>○ Pay as you use at a low cost of Rs.1 /- for every 1000 words of request & response.</li>
                        </ul>
                    </div>
                </div>
                <div className='col-md-5'>
                    <div className='order-summary'>
                        <label>Having Coupon Code ?</label>
                        <div className='d-flex align-items-center user-ip'>
                            <input type='text' className='form-control' placeholder='XXXXXX' value={coupon} onChange={(e) => {
                                setCoupon(e.target.value)
                                setCouponErr('')
                            }} />&nbsp;&nbsp;&nbsp;
                            {
                                subscriptionDetails?.discountAmount > 0 ?
                                    <button className='apply-btn' style={{ background: "#EC2B4E" }} disabled={!coupon} onClick={removeCoupon}>Remove</button>
                                    :
                                    <button className='apply-btn' disabled={!coupon} onClick={applyCoupon}>Apply</button>
                            }

                        </div>
                        <span style={{ color: 'red' }}>{couponErr}</span>
                        <div style={{ borderBottom: '1px solid #e5e5e5' }}></div>
                        <h3>Order Summary</h3>
                        <div style={{ borderBottom: '1px solid #e5e5e5' }}></div>
                        <div className='order-details'>
                            <div className='d-flex justify-content-between align-items-center'>
                                <p>SubTotal:</p>
                                <h5>₹ {subscriptionDetails?.subTotalAmount.toFixed(2)}</h5>
                            </div>
                            <div className=' d-flex justify-content-between align-items-center'>
                                <p>Discount:</p>
                                <h5 style={{ color: subscriptionDetails?.discountAmount === 0 ? '' : '#EC2B4E' }}>-₹ {subscriptionDetails?.discountAmount?.toFixed(2)}</h5>
                            </div>
                            <div className=' d-flex justify-content-between align-items-center'>
                                <p>Grand Total:</p>
                                <h5 style={{ fontSize: 24 }}>₹ {subscriptionDetails?.totalAmount?.toFixed(2)}</h5>
                            </div>
                        </div>
                        <div className='form-group'>
                            <button className='form-control pay-btn' onClick={proceed}>PROCEED TO PAYMENT</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
