import React, { useEffect, useState } from 'react';
import './mySubscriptions.scss';
import { useDispatch, useSelector } from 'react-redux';
import { getMySubscriptions } from '../../Redux/ActionCreators/subscriptionAction';
import moment from 'moment';
import { isExpired } from '../../utils/miscellneous';
import TablePagination from '../../utils/tablePagination';

export default function Mysubscriptions() {
    const [page, setPage] = useState(1);
    const dispatch = useDispatch();
    const { mySubscriptions } = useSelector(state => state.subscription);

    useEffect(() => {
        dispatch(getMySubscriptions(1));
    }, [])

    const goToPrev = () => {
        setPage(page - 1);
        dispatch(getMySubscriptions(page - 1));
    }

    const goToNext = () => {
        setPage(page + 1);
        dispatch(getMySubscriptions(page + 1));
    }


    return (
        <div className='my-subscription-holder'>
            <h2>My Subscriptions</h2>
            {
                mySubscriptions?.rows?.map(data => {
                    return (
                        <div key={data.id} className={isExpired(data?.expireAt) ? 'subscription-item expire' : 'subscription-item'}>
                            <div className='d-flex justify-content-between'>
                                <div>
                                    <p>{data?.name}</p>
                                    <div className='master-catlog'>{data?.masterCatalogs?.map(el1 => el1.masterCatalogType).join(', ')}</div>
                                </div>
                                <div>
                                    <div className='sub-date'>Expire on :&nbsp;&nbsp; <aside >{moment(data?.expireAt).format('DD/MM/YYYY')}</aside></div>
                                    <div className='sub-date mt-2'>Purchased on :&nbsp;&nbsp; <aside>{moment(data?.createdAt).format('DD/MM/YYYY')}</aside></div>

                                    {data.couponInfo ?
                                        (
                                            <div className='sub-date mt-2'>Coupon code :&nbsp;&nbsp; <aside style={{ color: "#29910d" }}>{data?.couponInfo?.couponCode}</aside></div>
                                        )
                                        : ""
                                    }
                                </div>
                            </div>
                            <div className='hr' ></div>
                            <div className='sub-desc'>
                                <div>
                                    <ul>

                                        {
                                            data?.description?.split('\n')?.map(el1 => {
                                                return (
                                                    <li key={el1}><img src='/svg-icons/check.svg' alt='' /> &nbsp;&nbsp;{el1}</li>
                                                )
                                            })
                                        }
                                    </ul>
                                </div>
                                <div className='order-details'>
                                    <div className='d-flex justify-content-between align-items-center mb-2'>
                                        <p>SubTotal:</p>
                                        <h5>₹ {data?.subTotalAmount.toFixed(2)}</h5>
                                    </div>
                                    <div className=' d-flex justify-content-between align-items-center mb-2'>
                                        <p>Discount:</p>
                                        <h5 style={{ color: data?.discountAmount === 0 ? '' : '#EC2B4E' }}>-₹ {data?.discountAmount?.toFixed(2)}</h5>
                                    </div>
                                    <div className=' d-flex justify-content-between align-items-center mb-2'>
                                        <p>Grand Total:</p>
                                        <h5 style={{ fontSize: 24 }}>₹ {data?.totalAmount?.toFixed(2)}</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                })
            }
            {
                (mySubscriptions?.totalPages > 1) ?
                    <div>
                        <TablePagination
                            goToPrev={goToPrev}
                            goToNext={goToNext}
                            data={mySubscriptions}
                        />
                    </div> :
                    null
            }

        </div >
    )
}
