import React, { Fragment, useEffect, useState } from 'react';
import './header.scss';
import { Outlet, useNavigate, Link } from 'react-router-dom';
import { getProfile, logOutUser } from '../Redux/ActionCreators/profileAction';
import { getContents, setLang } from '../Redux/ActionCreators/homepageAction';
import { useDispatch, useSelector } from 'react-redux';
import Footer from './footer';
import Snackbar from '@mui/material/Snackbar';
import { Alert } from '@mui/material';
import { clearErrors } from '../Redux/ActionCreators/errorAction';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import $ from 'jquery';

const sideMenuList = [
    {
        title: 'My Favorites',
        icon: "./svg-icons/favorite-icon.svg",
        activeIcon: "./svg-icons/favorite-active-icon.svg",
        subMenu: [
            {
                text: "Favorite Recipes",
                action: "favorite-recipes",
                id: "fr",
            },
            {
                text: "Favorite Fitness",
                action: "favorite-fitness",
                id: "ff",
            },
        ]
    }
]

function Sidebar() {
    const [show, setShow] = useState(false);
    const [active, setActive] = useState('My-Favorites');
    const [activeTab, setActiveTab] = useState('profile');
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const profile = useSelector(state => state.profile);
    const { activeLanguage, languages } = useSelector(state => state.home)
    const error = useSelector(state => state.error);

    useEffect(() => {
        // if (localStorage.getItem('userAuthToken')) {
        //     navigate('/', { replace: true })
        // }
        const firstRender = async () => {
            dispatch(getProfile());
            dispatch(getContents());
            updateDimensions();
            setActive((window.location.pathname.split('/')[1] === 'favorite-recipes' || window.location.pathname.split('/')[1] === 'favorite-fitness') ? 'My Favorites' : '')
            setActiveTab(window.location.pathname.split('/')[1])
        }

        firstRender();
    }, [])


    const setLanguages = (e) => {
        dispatch(setLang(e));
    }




    const logout = async () => {
        dispatch(logOutUser());
        localStorage.removeItem('userAuthToken');
        localStorage.removeItem('loginDetails');
        navigate('/', { replace: true })
    }



    const updateDimensions = () => {
        let w = window.screen.width;
        if (w <= 1024) {
            document.body.classList.add("BodyCloseNav");
        } else {
            document.body.classList.remove("BodyCloseNav");
            document.body.classList.add("scrollhidden");
        }
    };

    const getIntial = (fname, lname) => {
        const i1 = fname?.split('')[0]
        const i2 = lname?.split('')[0]
        return i1 + i2
    }

    const { profileDetails } = profile

    const snackClosed = () => {
        dispatch(clearErrors());
    }

    const setActiveMenu = async (e, type) => {
        e.preventDefault();
        e.stopPropagation();
        await setActiveTab('favorite-recipes');
        navigate('/favorite-recipes');
        if (active === type) {
            setActive('');
        } else {
            setActive(type)
        }
    }

    const isTabActive = (pathname) => {
        setActiveTab(pathname);
        if (pathname === 'favorite-fitness' || pathname === 'favorite-recipes') {
            setActive('My Favorites');
        } else {
            setActive('');
        }
        if (window.innerWidth < 767) {
            document.body.classList.add("BodyCloseNav");
            document.body.classList.remove("scrollhidden");
        }
    }

    const isSidebar = () => {
        if ($("body").hasClass("BodyCloseNav")) {
            document.body.classList.remove("BodyCloseNav");
            document.body.classList.add("scrollhidden");
        } else {
            document.body.classList.add("BodyCloseNav");
            document.body.classList.remove("scrollhidden");
        }
    };

    return (
        <Fragment>
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'center', }}
                open={error.successError}
                autoHideDuration={5000}
                onClose={snackClosed}
            >
                <Alert onClose={snackClosed} severity="error" sx={{ width: '100%' }}>
                    {error.messageText}
                </Alert>
            </Snackbar>
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'center', }}
                open={error.successMessage}
                autoHideDuration={5000}
                onClose={snackClosed}
            >
                <Alert onClose={snackClosed} severity="success" sx={{ width: '100%' }}>
                    {error.messageText}
                </Alert>
            </Snackbar>
            <div className='dashboard-container'>
                <Navbar expand="lg" className="bg-body-tertiary bg-orange">
                    <Container fluid>
                        <Navbar.Brand >
                            <div className='cursor-pointer'>
                                <img
                                    src='./logo.png' alt='' width={window.innerWidth > 767 ? 270 : 260}
                                    className='rounded logo-img'
                                    onClick={() => {
                                        setShow(false)
                                        navigate('/', { replace: true })
                                    }}
                                />
                            </div>
                        </Navbar.Brand>

                        <Navbar.Toggle aria-controls="navbarScroll" aria-label="Toggle navigation" className="navbar-toggler" onClick={() => setShow(!show)} />
                        <div className={show ? 'navbar-collapse collapse show' : 'navbar-collapse collapse not-show'}>
                            <Nav
                                className="mx-auto my-2 my-lg-0"
                                style={{ maxHeight: '100px' }}
                                navbarScroll
                            >
                                <li className="nav-item" onClick={() => {
                                    setShow(false)
                                    navigate('/', { replace: true })
                                }}>
                                    <span className="nav-link">Home</span>
                                </li>
                                <li className="nav-item" onClick={() => {
                                    setShow(false)
                                    navigate('/about-us')
                                }}>
                                    <span className="nav-link">About us</span>
                                </li>
                                <li className="nav-item" onClick={() => {
                                    setShow(false)
                                    navigate('/contact-us')
                                }}>
                                    <span className="nav-link">Contact us</span>
                                </li>
                                <li className="nav-item" onClick={() => {
                                    setShow(false)
                                    navigate('/subscriptions')
                                }}>
                                    <span className="nav-link">Subscriptions</span>
                                </li>
                                <li className="nav-item">
                                    <span className="nav-link">
                                        <div id="google_translate_element"></div>
                                    </span>
                                </li>

                                {/* <NavDropdown title={activeLanguage} id="navbarScrollingDropdown">
                                    <li className="nav-item dropdown">
                                        {
                                            languages?.map(el => {
                                                return (
                                                    <NavDropdown.Item className='text-uppercase' onClick={() => setLanguages(el.label)}>{el.label}</NavDropdown.Item>
                                                )
                                            })
                                        }
                                    </li>
                                </NavDropdown> */}

                                {window.innerWidth > 767 ? null :
                                    localStorage.getItem('userAuthToken') ?
                                        <Fragment>
                                            <li className="nav-item" onClick={() => navigate('/profile')}>
                                                <span className="nav-link">Profile</span>
                                            </li>
                                            <li className="nav-item" onClick={() => logout()}>
                                                <span className="nav-link">Logout</span>
                                            </li>
                                        </Fragment> :
                                        <div className="d-flex nav-btns">
                                            <button onClick={() => {
                                                setShow(false)
                                                navigate('/login')
                                            }} >Login</button>&nbsp;&nbsp;&nbsp;&nbsp;
                                            <button onClick={() => {
                                                setShow(false)
                                                navigate('/signup')
                                            }} >Sign up</button>
                                        </div>
                                }
                            </Nav>
                            {
                                window.innerWidth > 767 ?
                                    <div className="dashboard-profile-holder">
                                        {localStorage.getItem('userAuthToken') ?
                                            <div className='d-flex align-items-center'>
                                                <h4 className='mb-0 text-white'>{profileDetails?.firstName} {profileDetails?.lastName}</h4>&nbsp;&nbsp;
                                                <div className="dropmain-holder d-inline-block">
                                                    <div
                                                        className="drop-profile-hold initial-circle-custom"
                                                    >

                                                        {profileDetails?.image ? (
                                                            <img
                                                                src={profileDetails?.image} alt=''
                                                                onError={(e) => {
                                                                    if (e.target.src !== "./images/placeholder-image.jpg") {
                                                                        e.target.onerror = "./images/placeholder-image.jpg";
                                                                        e.target.src = "./images/placeholder-image.jpg";
                                                                    }
                                                                }}
                                                            />
                                                        ) :
                                                            getIntial(profileDetails?.firstName, profileDetails?.lastName)

                                                        }
                                                    </div>
                                                    <div className="drop-inst">

                                                        <div className="logout" onClick={() => navigate('/profile')}>
                                                            Profile
                                                        </div>
                                                        <div className="logout" onClick={() => logout()}>
                                                            Sign Out
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> :
                                            <div className="d-flex nav-btns">
                                                <button onClick={() => navigate('/login')} >Login</button>&nbsp;&nbsp;&nbsp;&nbsp;
                                                <button onClick={() => navigate('/signup')} >Sign up</button>
                                            </div>
                                        }
                                    </div> :
                                    null

                            }
                        </div>
                    </Container>
                </Navbar>
                <div className='d-flex align-items-start align-self-stretch'>
                    <div className="side-bar align-self-stretch">
                        <div className="menu-close">
                            <button
                                type="button"
                                className="close"
                                onClick={() => isSidebar()}
                                aria-label="Close"
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className='profile-overflow'>
                            <div className="dashboard-links">
                                <ul>
                                    <Link
                                        to={`/profile`}
                                        onClick={() => isTabActive('profile')}
                                    >
                                        <li
                                            id={"sidebar-item-0"}
                                            className={
                                                activeTab === 'profile'
                                                    ? "dashboard-active"
                                                    : null
                                            }
                                        >
                                            <img
                                                src={activeTab === 'profile' ?
                                                    './svg-icons/profile-active-icon.svg' :
                                                    './svg-icons/profile-icon.svg'
                                                }
                                                alt=''
                                            />
                                            My Profile
                                        </li>
                                    </Link>
                                    <Link
                                        to={`/my-subscription`}
                                        onClick={() => isTabActive('my-subscription')}
                                    >
                                        <li
                                            id={"sidebar-item-1"}
                                            className={
                                                activeTab === 'my-subscription'
                                                    ? "dashboard-active"
                                                    : null
                                            }
                                        >
                                            <img
                                                src={activeTab === 'my-subscription' ?
                                                    './svg-icons/subscription-active-icon.svg' :
                                                    './svg-icons/subscription-icon.svg'
                                                }
                                                alt=''
                                            />
                                            My Subscriptions
                                        </li>
                                    </Link>
                                    {sideMenuList.map((el, i) => {
                                        return (
                                            <div key={i} className="card" style={{ background: active === el.title ? '#feeee4' : "transparent", color: active === el.title ? '#000000' : "#fff" }}>
                                                <div className={active === el.title ? "card-header" : "card-header collapsed"} data-toggle="collapse" href={`#collapse${i}`} onClick={(e) => setActiveMenu(e, el.title)} >
                                                    <div className='cursor-pointer'>
                                                        <img
                                                            src={active === el.title ? el.activeIcon : el.icon}
                                                            width={23}
                                                            alt=''
                                                        />&nbsp; &nbsp; &nbsp; {el.title}
                                                    </div>
                                                </div>
                                                <div className={active === el.title ? "collapse show mt-1" : "collapse mt-1"} data-parent="#accordion1">
                                                    {
                                                        el.subMenu.map(items => {
                                                            return (
                                                                <Link
                                                                    to={`/${items.action}`}
                                                                >
                                                                    <li
                                                                        style={{ paddingLeft: 75, color: "#000000" }}
                                                                        id={items.id}
                                                                        key={items.id}
                                                                        onClick={() => isTabActive(items.action)}
                                                                        className={
                                                                            activeTab === items.action
                                                                                ? "dashboard-active1"
                                                                                : null
                                                                        }
                                                                    >
                                                                        {items.text}
                                                                    </li>
                                                                </Link>

                                                            )
                                                        })
                                                    }

                                                </div>
                                            </div>
                                        );
                                    })}
                                    <Link
                                        to={`/search-history`}
                                        onClick={() => isTabActive('search-history')}
                                    >
                                        <li
                                            id={"sidebar-item-3"}
                                            className={
                                                activeTab === 'search-history'
                                                    ? "dashboard-active"
                                                    : null
                                            }
                                        >
                                            <img
                                                src={activeTab === 'search-history' ?
                                                    './svg-icons/search-active-icon.svg' :
                                                    './svg-icons/search-icon.svg'

                                                }
                                                alt=''
                                            />
                                            Search History
                                        </li>
                                    </Link>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className='layout-container' style={{ paddingLeft: window.innerWidth < 767 ? 15 : 50 }} >
                        <React.Suspense fallback={''}>
                            <Outlet />
                        </React.Suspense>
                    </div>
                </div>
                <div>
                    <Footer />
                </div>
            </div>
        </Fragment >

    )
}




export default Sidebar
