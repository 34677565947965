import { getApiCall, postApiCall } from '../../utils/requests';
import { returnErrors } from '../ActionCreators/errorAction';
import { GET_COUPONS, GET_SUBSCRIPTIONS, GET_SUBSCRIPTION_DETAILS, PAYMENT_PROCEED, GET_MY_SUBSCRIPTIONS } from '../Types/types';


export const getSubscriptions = () => async (dispatch) => {
    try {
        const response = await getApiCall(`/subscriptions/website`);
        if (response.data.statusCode === 200) {
            dispatch({ type: GET_SUBSCRIPTIONS, payload: response.data.data });
        }
        else {
            returnErrors(dispatch, response.data.message, response.status);
        }
    } catch (error) {
        returnErrors(dispatch, error.response?.data.message, error.response?.status)
    }
}

export const getSubscriptionDetails = (id, cc) => async (dispatch) => {
    let obj = {
        "subscriptionId": id,
        "couponCode": cc ? cc : ""
    }
    try {
        const response = await postApiCall(`/user-subscriptions/amounts`, obj);
        if (response.data.statusCode === 200) {
            return dispatch({ type: GET_SUBSCRIPTION_DETAILS, payload: response.data.data });
        }
        else {
            returnErrors(dispatch, response?.data?.message, response.status);
        }
    } catch (error) {
        if (error?.response?.status === 400) {
            return dispatch({ type: 'SUBSCRIPTION_ERROR', payload: error?.response.data.message });
        }
        returnErrors(dispatch, error.response?.data.message, error.response?.status)
    }
}

export const paymentProceed = (obj) => async (dispatch) => {

    try {
        const response = await postApiCall(`/user-subscriptions`, obj);
        if (response.data.statusCode === 200) {
            return dispatch({ type: PAYMENT_PROCEED, payload: response.data.data });
        }
        else {
            returnErrors(dispatch, response?.data?.message, response.status);
        }
    } catch (error) {
        if (error?.response?.status === 400) {
            returnErrors(dispatch, error.response?.data.message, error.response?.status)
            return dispatch({ type: 'SUBSCRIPTION_ERROR', payload: error?.response.data.message });
        }
    }
}

export const getCoupons = () => async (dispatch) => {
    try {
        const response = await getApiCall(`/coupons/website`);
        if (response.data.statusCode === 200) {
            dispatch({ type: GET_COUPONS, payload: response.data.data });
        }
        else {
            returnErrors(dispatch, response.data.message, response.status);
        }
    } catch (error) {
        returnErrors(dispatch, error.response?.data.message, error.response?.status)
    }
}

//My subscription
export const getMySubscriptions = (page) => async (dispatch) => {
    try {
        const response = await getApiCall(`/user-subscriptions?page=${page}`);
        if (response.data.statusCode === 200) {
            dispatch({ type: GET_MY_SUBSCRIPTIONS, payload: response.data.data });
        }
        else {
            returnErrors(dispatch, response.data.message, response.status);
        }
    } catch (error) {
        returnErrors(dispatch, error.response?.data.message, error.response?.status)
    }
}