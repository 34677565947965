import './App.scss';
import { Routes, Route, useNavigate } from 'react-router-dom';
import { Fragment, useEffect } from 'react';
import Header from './Layouts/header';
import routeItems from './Routes/routes';
import Notfound from './Components/Not-found';
import Sidebar from './Layouts/sidebar';

function App() {
  const isLogged = !!localStorage.getItem('userAuthToken')
  const navigate = useNavigate();


  // useEffect(() => {
  //   if (isLogged) {
  //     navigate('/')
  //   }
  // }, [])

  return (
    <div >
      <Routes>
        {
          routeItems?.map(el => {
            if (el.layout === 'mainLayout') {
              return (
                <Fragment key={el.path}>
                  <Route element={<Header />} >
                    <Route path={el.path} element={el.component} />
                  </Route>
                </Fragment>
              )
            }
          })
        }
        {
          routeItems?.map(el => {
            if (el.layout === 'sidebarLayout') {
              return (
                <Fragment key={el.path}>
                  <Route element={isLogged ? <Sidebar /> : null} >
                    <Route path={el.path} element={el.component} />
                  </Route>
                </Fragment>
              )
            }
          })
        }
        <Route path='*' element={<Notfound />} />
      </Routes>

    </div>
  );
}

export default App;
