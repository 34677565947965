import React from 'react';
import Home from '../Components/HomePage/home';
import Faq from '../Components/Faq/Faq';
import PrivacyPolicy from '../Components/Contents/privacyPolicy';
import LegalPolicy from '../Components/Contents/legalPolicy';
import Aboutus from '../Components/Contents/aboutus';
import Login from '../Components/Login/login';
import Signup from '../Components/Signup/signup';
import Contactus from '../Components/Contents/contactus';
import Recipes from '../Components/Recipes/recipes';
import Subscriptions from '../Components/Subscriptions/subscriptions';
import Mysubscriptions from '../Components/My-Subscriptions/mySubscriptions';
import FavoriteRecipes from '../Components/My-Favorites/favoriteRecipes';
import FavoriteFitness from '../Components/My-Favorites/favoriteFitness';
import SearchHistory from '../Components/Search-History/searchHistory';
import Checkout from '../Components/Subscriptions/checkout';

const Profile = React.lazy(() => import('../Components/Profile/profile'));

const routeItems = [
    { path: '/login', component: <Login />, layout: 'mainLayout' },
    { path: '/signup', component: <Signup />, layout: 'mainLayout' },
    { path: '/', component: <Home />, layout: 'mainLayout' },
    { path: '/faqs', component: <Faq />, layout: 'mainLayout' },
    { path: '/privacy-policy', component: <PrivacyPolicy />, layout: 'mainLayout' },
    { path: '/legal-policy', component: <LegalPolicy />, layout: 'mainLayout' },
    { path: '/about-us', component: <Aboutus />, layout: 'mainLayout' },
    { path: '/contact-us', component: <Contactus />, layout: 'mainLayout' },
    { path: '/recipes', component: <Recipes />, layout: 'mainLayout' },
    { path: '/subscriptions', component: <Subscriptions />, layout: 'mainLayout' },
    { path: '/checkout', component: <Checkout />, layout: 'mainLayout' },
    { path: '/profile', component: <Profile />, layout: 'sidebarLayout' },
    { path: '/my-subscription', component: <Mysubscriptions />, layout: 'sidebarLayout' },
    { path: '/favorite-recipes', component: <FavoriteRecipes />, layout: 'sidebarLayout' },
    { path: '/favorite-fitness', component: <FavoriteFitness />, layout: 'sidebarLayout' },
    { path: '/search-history', component: <SearchHistory />, layout: 'sidebarLayout' },


]

export default routeItems