import { GET_SEARCH_HISTORY, DELETE_SEARCH_HISTORY } from '../Types/types';

let intialState = {
    searchHistory: null
}

export default function searchHistoryReducer(state = intialState, action) {
    switch (action.type) {
        case GET_SEARCH_HISTORY:
            return { ...state, searchHistory: action.payload };
        case DELETE_SEARCH_HISTORY:
            if (action.payload) {
                return {
                    ...state, searchHistory: { ...state.searchHistory, rows: state.searchHistory?.rows?.filter(el => el.id !== action.payload) }
                }
            } else {
                return {
                    ...state, searchHistory: { ...state.searchHistory, rows: [] }
                }
            }
        default:
            return state;
    }
}
