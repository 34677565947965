import { deleteApiCall, getApiCall } from '../../utils/requests';
import { returnErrors } from '../ActionCreators/errorAction';
import { GET_SEARCH_HISTORY, DELETE_SEARCH_HISTORY } from '../Types/types';


export const getSearchHistory = (page, search) => async (dispatch) => {
    try {
        const response = await getApiCall(`/openai-data/search-history?page=${page}&keyword=${search}`);
        if (response.data.statusCode === 200) {
            dispatch({ type: GET_SEARCH_HISTORY, payload: response.data.data });
        }
        else {
            returnErrors(dispatch, response.data.message, response.status);
        }
    } catch (error) {
        returnErrors(dispatch, error.response?.data.message, error.response?.status)
    }
}

export const deleteSearchHisotry = (id) => async (dispatch) => {
    try {
        const response = await deleteApiCall(`/openai-data/search-history?searchHistoryId=${id}`);
        if (response.data.statusCode === 200) {
            returnErrors(dispatch, response.data?.message, response.status);
            dispatch({ type: DELETE_SEARCH_HISTORY, payload: id });
        }
        else {
            returnErrors(dispatch, response.data.message, response.status);
        }
    } catch (error) {
        returnErrors(dispatch, error.response?.data.message, error.response?.status)
    }
}