import React from 'react';
import Pagination from 'react-bootstrap/Pagination';


export default function TablePagination({ data, goToNext, goToPrev }) {
    return (
        <div className='paginated-form'>
            <div className='d-flex justify-content-end align-items-center' style={{ marginRight: 45 }} >
                <div className='d-flex align-items-center' >
                    <span style={{ width: '70%', color: '#696873', fontSize: 14 }}> Page {data?.page} of {data?.totalPages}</span>&nbsp;&nbsp;&nbsp;
                    <Pagination>
                        <Pagination.Prev style={{ pointerEvents: data?.page === 1 ? 'none' : 'auto' }} onClick={() => goToPrev()} />
                        <Pagination.Next style={{ pointerEvents: data?.totalPages === data?.page ? 'none' : 'auto' }} onClick={() => goToNext()} />
                    </Pagination>
                </div>

            </div>
        </div>
    )
}
