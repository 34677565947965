import { GET_ERRORS, CLEAR_ERRORS } from '../Types/types';

const intialState = {
    messageText: '',
    status: null,
    id: null,
    successError: false,
    successMessage: false,
};

export default function errorReducer(state = intialState, action) {
    let stateCopy = JSON.parse(JSON.stringify(state));
    switch (action.type) {
        case GET_ERRORS:
            if (action.payload.status === 401) {
                localStorage.removeItem('userAuthToken');
                localStorage.removeItem('loginDetails');
                stateCopy.status = action.payload.status;
                if ((window.location.pathname !== '/login')) {
                    window.location.href = '/login'
                }
            }
            else if (action.payload.status === 404) {
                stateCopy.successError = true;
                stateCopy.messageText = action.payload.msg || 'Something went wrong';
                stateCopy.status = action.payload.status;
            }
            else if (action.payload.status === 500 || action.payload.status === 400) {
                stateCopy.successError = true;
                stateCopy.messageText = action.payload.msg;
                stateCopy.status = action.payload.status;
            }
            else if (action.payload.status === 200) {
                stateCopy.successMessage = true;
                stateCopy.messageText = action.payload.msg;
                stateCopy.status = action.payload.status;
            }

            return stateCopy;
        case CLEAR_ERRORS:
            stateCopy.successMessage = false;
            stateCopy.successError = false;
            stateCopy.messageText = '';
            stateCopy.status = '';
            return stateCopy;
        default:
            return stateCopy;
    }
}