import axios from "axios";

export function SampleNextArrow(props) {
    const { className, onClick } = props;
    return (
        <div
            className={className}
            onClick={onClick}
        >
            <img src="./png-icons/right-arrow.png" width='25px' alt='' />
        </div>
    );
}

export function SamplePrevArrow(props) {
    const { className, onClick } = props;
    return (
        <div
            className={className}
            onClick={onClick}
        >
            <img src="./png-icons/left-arrow.png" width='25px' alt='' />
        </div>
    );
}

export const getIPAddress = async () => {
    try {
        const ipAddress = await axios.get('https://api.ipify.org/?format=json');; // Wait for the promise to resolve and store the result in a variable
        return ipAddress.data.ip;
    } catch (error) {
        console.error('Error fetching IP address:', error);
    }
}

export function isExpired(dateToCheck) {
    const today = new Date();
    const date = new Date(dateToCheck);

    // Set the time of both dates to the start of the day for an accurate comparison
    today.setHours(0, 0, 0, 0);
    date.setHours(0, 0, 0, 0);

    // Check if the given date is in the past
    return date < today;
}