import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getFaqs } from '../../Redux/ActionCreators/homepageAction';
import Accordion from 'react-bootstrap/Accordion';

export default function Faq() {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getFaqs());
    }, [])

    const faqs = useSelector(state => state.home.faqs);

    return (
        <div className='content-holder'>
            <div className='banner-image' >
                <img src='./images/background-images/aboutus.jpg' alt='' />
                <h5>FAQs</h5>
            </div>
            <div className='content mt-3' style={{ width: '100%', padding: window.innerWidth > 767 ? '70px 250px' : '25px 15px' }}>
                <Accordion defaultActiveKey='0' >
                    {
                        faqs?.map((el, i) => {
                            return (
                                <Accordion.Item key={el.id} eventKey={`${i}`}>
                                    <Accordion.Header>{el.question}</Accordion.Header>
                                    <Accordion.Body>
                                        {el.answer}
                                    </Accordion.Body>
                                </Accordion.Item>
                            )
                        })
                    }
                </Accordion>
            </div>
        </div>
    )
}
