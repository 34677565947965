/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Fragment, useEffect, useState } from 'react';
import "./login.scss";
import Snackbar from '@mui/material/Snackbar';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { userLogin, forgotPassword, changedPassword, socialLogin } from '../../Redux/ActionCreators/authAction';
import { clearErrors } from '../../Redux/ActionCreators/errorAction';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { Alert } from '@mui/material';
import { LoginSocialFacebook, LoginSocialGoogle, LoginSocialApple } from 'reactjs-social-login';



function Login() {
    const [provider, setProvider] = useState('');
    const [profile, setProfile] = useState('');
    const [defaultShow, setDefaultShow] = useState(true);
    const [resetValue, setResetValue] = useState({ otp: '', password: '' });
    const [isResetPassword, setISResetPassword] = useState(false);
    const [resetPasswordComplete, setResetPasswordComplete] = useState(false);
    const [emailError, setEmailError] = useState('');
    const [otpEmail, setOtpEmail] = useState('');
    const [isOtpEmailLoading, setIsOtpEmailLoading] = useState(false);
    const [otpEnable, setOtpEnable] = useState(false);
    const [emailOtpScreen, setEmailOtpScreen] = useState(true);
    const [successEnable, setSuccessEnable] = useState(false);
    const [messageText, setMessageText] = useState('');
    const [timer, setTimer] = useState(60);
    const [isRunning, setIsRunning] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const error = useSelector(state => state.error);
    const location = useLocation();

    const initialValues = {
        email: '',
        password: ''
    }

    const loginSchema = Yup.object({
        email: Yup.string()
            .required('Email is required')
            .matches(
                /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                'Invalid email address'
            ),
        password: Yup.string().required('Password is required'),
    })

    const resetPasswordSchema = Yup.object({
        otp: Yup.string().matches(/^\d+$/, "Must be only digits")
            .min(4, 'Must be exactly 4 digits')
            .max(4, 'Must be exactly 4 digits')
            .required('OTP is required'),
        password: Yup.string().min(8, 'Min 8 char allowed')
            .max(15, 'Max 15 char allowed').required('Password is required'),
        confirm_password: Yup.string()
            .oneOf([Yup.ref('password'), null], 'Passwords must match')
            .required('Confirm Password is required'),
    })

    // Check if the user is already logged in
    useEffect(() => {
        window.scrollTo(0, 0);
        // Replace this condition with your actual check for whether the user is logged in
        const userIsLoggedIn = localStorage.getItem('userAuthToken') !== null
        if (userIsLoggedIn) {
            // Redirect to the dashboard page
            navigate('/');
        }
    }, [location]);

    useEffect(() => {
        let interval;

        if (isRunning && timer > 0) {
            interval = setInterval(() => {
                setTimer(timer - 1);
            }, 1000);
        } else if (timer === 0) {
            clearInterval(interval);
        }

        return () => {
            clearInterval(interval);
        };
    }, [timer, isRunning]);


    useEffect(() => {
        if (window.location.href.includes("otp")) {
            setDefaultShow(false);
            setResetValue({ otp: window.location.search.split("=")[1], password: '' })
        }
    }, [])

    const handleSubmit = async (values) => {
        let login = {
            username: values.email,
            password: values.password
        }

        const isLogin = await dispatch(userLogin(login));
        if (isLogin?.type === 'USER_LOGIN') {
            // navigate('/')
        } else {
            navigate('/login')
        }
    }


    const resetPassword = () => {
        setISResetPassword(true);
        setOtpEnable(false);
        setEmailOtpScreen(true);
        setOtpEmail('');
        setEmailError('');
    }

    const onOtpEmailChange = (e) => {
        setEmailError('');
        setOtpEmail(e.target.value);
    }



    const handleOtpEmail = async () => {
        // eslint-disable-next-line no-useless-escape
        let emailrex = /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!otpEmail) {
            setEmailError(`Email is required`);
            return false;
        }
        else if (!emailrex.test(String(otpEmail))) {
            setEmailError("Email is not valid");
            return false;
        }
        else {
            setIsOtpEmailLoading(true);
            let obj = {
                "email": otpEmail,
                "otpFor": "forgetPassword"
            }
            const res = await dispatch(forgotPassword(obj));
            if (res?.type === 'FORGET_PASSWORD') {
                await setIsOtpEmailLoading(false);
                await setOtpEnable(true);
                await setEmailOtpScreen(false);
                await setResetPasswordComplete(false)
                await setIsRunning(true);
            } else {
                await setIsOtpEmailLoading(false);
            }

        }
    }


    const onOtpEmailChangeKeyPress = async (e) => {
        // eslint-disable-next-line no-restricted-globals
        if (e.which === 13 || event.keyCode === 13) {
            // eslint-disable-next-line no-useless-escape
            let emailrex = /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if (!otpEmail) {
                setEmailError(`Email can't be blank`);
                return false;
            }
            else if (!emailrex.test(String(otpEmail))) {
                setEmailError("Email is not valid");
                return false;
            }
            else {
                let obj = {
                    "email": otpEmail,
                    "otpFor": "forgetPassword"
                }
                const res = await dispatch(forgotPassword(obj));
                if (res?.type === 'FORGET_PASSWORD') {
                    await setIsOtpEmailLoading(false);
                    await setOtpEnable(true);
                    await setEmailOtpScreen(false);
                    await setResetPasswordComplete(false);
                    await setIsRunning(true);
                } else {
                    await setIsOtpEmailLoading(false);
                }
            }
        }
    }

    const resendOtp = async () => {
        let obj = {
            "email": localStorage.getItem('email'),
            "otpFor": "forgetPassword"
        }
        const res = await dispatch(forgotPassword(obj));
        if (res?.type === 'FORGET_PASSWORD') {
            setTimer(60);
            setIsRunning(true);
        }
    }

    const resetTimer = () => {
        setTimer(60);
        setIsRunning(false);
    };

    const handleResetSubmit = async (values) => {
        let obj = {
            email: otpEmail || localStorage.getItem('email'),
            otpNumber: values.otp,
            newPassword: values.password,
        };
        const res = await dispatch(changedPassword(obj));
        if (res?.type === 'PASSWORD_CHANGED') {
            setResetPasswordComplete(true);
            resetTimer();
            redirectTologin();
        }
    };

    const redirectTologin = () => {
        setDefaultShow(true);
        setISResetPassword(false);
    }

    const snackClosed = () => {
        dispatch(clearErrors());
        setSuccessEnable(false);
        setMessageText('')
    }



    const minutes = Math.floor(timer / 60);
    const seconds = timer % 60;

    let content

    if (resetPasswordComplete === false)
        content = (
            <div className="otp-fields d-flex flex-column ">
                <h6 className='m-0'>
                    OTP Verification
                </h6>
                <p style={{ color: '#8D8C8F', fontSize: 16, fontWeight: 400 }}>We have Sent the OTP on your Email {localStorage.getItem('email')}</p>

                <Formik initialValues={resetValue} validationSchema={resetPasswordSchema} onSubmit={handleResetSubmit}>
                    {(formik) => (
                        <Form className="text-left">
                            <div className="row mt-4">
                                <div className="col-12 col-md-12">
                                    <div className="form-group">
                                        <Field type="text" name="otp" className="form-control" placeholder="Enter OTP *" />
                                        <ErrorMessage name="otp" component="div" className="error-message text-left" />
                                    </div>
                                </div>

                                <div className="col-12 col-md-12">
                                    <div className="form-group">
                                        <Field type="password" name="password" className="form-control" placeholder="Password" />
                                        <ErrorMessage name="password" component="div" className="error-message text-left" />
                                    </div>
                                </div>
                                <div className="col-12 col-md-12">
                                    <div className="form-group">
                                        <Field type="password" name="confirm_password" className="form-control" placeholder="Enter Confirm Password" />
                                        <ErrorMessage name="confirm_password" component="div" className="error-message text-left" />
                                    </div>
                                </div>

                            </div>
                            <div className='d-flex'>
                                <aside>Did't get OTP?</aside>&nbsp;
                                <aside onClick={timer === 0 ? resendOtp : null} style={{ color: timer === 0 ? '#6663FF' : '#8d8c8f', cursor: timer === 0 ? 'pointer' : '', fontWeight: 700 }}>Resend</aside>&nbsp;
                                <aside style={{ color: '#1B1A1F', fontWeight: 500 }}>({minutes.toString().padStart(2, '0')}:{seconds.toString().padStart(2, '0')})</aside>
                            </div>
                            <button type="submit" disabled={formik.isSubmitting} className='mt-3 button-color primary-button form-control'>Submit</button>
                        </Form>
                    )}
                </Formik>
                <h3
                    className="mt-2 cursor-pointer" style={{ fontSize: 15 }}
                    onClick={() => redirectTologin()}
                >
                    Back to Login
                </h3>
            </div>
        )
    else {
        content = (
            <div className="success-pasword-reset pb-3">
                <p style={{ fontSize: 18 }}>Password has been successfully changed</p>
                <button
                    onClick={() => redirectTologin()}
                    className="button-color form-control mt-4"
                >
                    Login
                </button>
            </div>
        )
    }


    return (
        <Fragment>
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'center', }}
                open={error.successError}
                onClose={() => snackClosed()}
                autoHideDuration={3000}
            >
                <Alert onClose={snackClosed} severity="error" sx={{ width: '100%' }}>
                    {error.messageText}
                </Alert>
            </Snackbar>
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'center', }}
                open={successEnable}
                onClose={() => snackClosed()}
                autoHideDuration={3000}
            >
                <Alert onClose={snackClosed} severity="succcess" sx={{ width: '100%' }}>
                    {messageText}
                </Alert>
            </Snackbar>
            <div className='login-module' style={{ backgroundImage: window.innerWidth > 767 ? '' : `url('./images/img1.png')` }}>
                {window.innerWidth > 767 && <img src='./images/background-images/img1.png' className='bg-image' alt='' />}
                <div className='row'>
                    <div className='col-md-7 col-12'>
                        <div className="login-text">
                            <section>Spice of Life</section>
                            <p className='mt-4'>Cinnamon, a popular spice, not only adds flavor but also helps regulate blood sugar, making it a healthy culinary choice.</p>
                        </div>
                    </div>
                    <div className='col-md-5 col-12'>
                        <div className="login-holder">
                            {
                                defaultShow ? (
                                    <div className="login-box">
                                        {!isResetPassword ? (
                                            <Fragment>
                                                <h6 className="text-left">Sign in</h6>
                                                <p style={{ color: '#8D8C8F', fontSize: 16, fontWeight: 400 }}>Fill in the form to Login your account</p>
                                                <Formik initialValues={initialValues} validationSchema={loginSchema} onSubmit={handleSubmit}>
                                                    {(formik) => (
                                                        <Form className="">
                                                            <div className="row mt-4">
                                                                <div className="col-12 col-md-12">
                                                                    <div className="form-group">
                                                                        <Field type="email" name="email" className="form-control" placeholder="Email Address *" />
                                                                        <ErrorMessage name="email" component="div" className="error-message" />
                                                                    </div>
                                                                </div>

                                                                <div className="col-12 col-md-12">
                                                                    <div className="form-group">
                                                                        <Field type="password" name="password" className="form-control" placeholder="Password *" />
                                                                        <ErrorMessage name="password" component="div" className="error-message" />
                                                                    </div>
                                                                </div>

                                                            </div>
                                                            <button type="submit" disabled={formik.isSubmitting} className='button-color primary-button'>Submit</button>
                                                        </Form>
                                                    )}
                                                </Formik>

                                                <div className='social-login'>
                                                    <LoginSocialGoogle
                                                        client_id={process.env.REACT_APP_GOOGLE_APP_ID}
                                                        scope="openid profile email"
                                                        onResolve={async ({ provider, data }) => {
                                                            let obj = {
                                                                "firstName": data.given_name,
                                                                "lastName": data.family_name,
                                                                "email": data.email,
                                                                "socialLoginId": data.sub,
                                                                "socialLoginType": provider
                                                            }
                                                            await dispatch(socialLogin(obj))
                                                        }}
                                                        onReject={err => {
                                                            console.log(err);
                                                        }}
                                                    >
                                                        <button className='oauth-btn' style={{ background: "#000000" }} ><img src='./images/g1.png' alt='' style={{ verticalAlign: 'middle' }} width={20} />&nbsp;&nbsp;Sign in with Google  </button>
                                                    </LoginSocialGoogle>

                                                    <LoginSocialFacebook
                                                        appId={process.env.REACT_APP_Facebook_APP_ID || ''}
                                                        fieldsProfile={
                                                            'id,first_name,last_name,middle_name,name,name_format,picture,short_name,email,gender'
                                                        }
                                                        onResolve={async ({ provider, data }) => {
                                                            let obj = {
                                                                "firstName": data.first_name,
                                                                "lastName": data.last_name,
                                                                "email": data.email,
                                                                "socialLoginId": data.id,
                                                                "socialLoginType": provider
                                                            }
                                                            await dispatch(socialLogin(obj))
                                                        }}
                                                        onReject={err => {
                                                            console.log(err);
                                                        }}
                                                    >
                                                        <button className='oauth-btn' style={{ background: "#000000" }} ><img src='./svg-icons/facebook.svg' alt='' style={{ verticalAlign: 'middle' }} width={20} />&nbsp;&nbsp; Sign in with Facebook</button>
                                                    </LoginSocialFacebook>

                                                    {/* <LoginSocialApple
                                                        client_id={process.env.REACT_APP_APPLE_ID || ''}
                                                        // scope={'name email'}
                                                        // redirect_uri={REDIRECT_URI}
                                                        // onLoginStart={onLoginStart}
                                                        onResolve={async ({ provider, data }) => {
                                                            let obj = {
                                                                "firstName": data.first_name,
                                                                "lastName": data.last_name,
                                                                "email": data.email,
                                                                "socialLoginId": data.id,
                                                                "socialLoginType": provider
                                                            }
                                                            await dispatch(socialLogin(obj))
                                                        }}
                                                        onReject={err => {
                                                            console.log(err);
                                                        }}
                                                    >
                                                        <button className='oauth-btn mt-2' style={{ background: "#000000" }}  ><img src='./png-icons/apple.png' alt='' style={{ verticalAlign: 'middle' }} width={20} />&nbsp;&nbsp;Sign In with Apple </button>
                                                    </LoginSocialApple> */}
                                                </div>

                                                <h3
                                                    className="forgot-password cursor-pointer "
                                                    onClick={() => resetPassword()}
                                                >
                                                    Forgot Password?
                                                </h3>
                                            </Fragment>
                                        ) : (
                                            <div className="otp-holder text-left">
                                                {(otpEnable === false &&
                                                    emailOtpScreen) ? (
                                                    <Fragment>
                                                        <p style={{ fontSize: 24 }} >Enter email to reset password</p>
                                                        <div className="form-group mt-3 w-100">
                                                            <input
                                                                type="email"
                                                                className="form-control"
                                                                aria-describedby="emailHelp"
                                                                placeholder="Email"
                                                                onChange={(e) => onOtpEmailChange(e)}
                                                                onKeyPress={(e) =>
                                                                    onOtpEmailChangeKeyPress(e)
                                                                }
                                                                value={otpEmail}
                                                            />
                                                            <div className='error'>{emailError}</div>
                                                        </div>
                                                        <button
                                                            onClick={() => handleOtpEmail()}
                                                            className="button-color primary-button position-relative mb-2"
                                                        >
                                                            {isOtpEmailLoading ? (
                                                                'Loading...'
                                                            ) : (
                                                                "Continue"
                                                            )}
                                                        </button>
                                                        <h3
                                                            className="mt-2 cursor-pointer" style={{ fontSize: 15 }}
                                                            onClick={() => redirectTologin()}
                                                        >
                                                            Back to Login
                                                        </h3>
                                                    </Fragment>
                                                ) :
                                                    content
                                                }
                                            </div>
                                        )}
                                    </div>
                                ) : (
                                    <div className="otp-holder">
                                        <h3>Set your password.</h3>
                                        {
                                            <div className="otp-fields">
                                                <h6 className='m-0'>
                                                    OTP Verification
                                                </h6>
                                                <p style={{ color: '#8D8C8F', fontSize: 16, fontWeight: 400 }}>We have Sent the OTP on your Email {localStorage.getItem('email')}</p>

                                                <Formik initialValues={resetValue} validationSchema={resetPasswordSchema} onSubmit={handleResetSubmit}>
                                                    {(formik) => (
                                                        <Form className="text-left">
                                                            <div className="row mt-4">
                                                                <div className="col-12 col-md-12">
                                                                    <div className="form-group">
                                                                        <Field type="text" name="otp" className="form-control" placeholder="Enter OTP *" />
                                                                        <ErrorMessage name="otp" component="div" className="error-message text-left" />
                                                                    </div>
                                                                </div>

                                                                <div className="col-12 col-md-12">
                                                                    <div className="form-group">
                                                                        <Field type="password" name="password" className="form-control" placeholder="Password *" />
                                                                        <ErrorMessage name="password" component="div" className="error-message text-left" />
                                                                    </div>
                                                                </div>
                                                                <div className="col-12 col-md-12">
                                                                    <div className="form-group">
                                                                        <Field type="password" name="confirm_password" className="form-control" placeholder="Confirm Password *" />
                                                                        <ErrorMessage name="confirm_password" component="div" className="error-message text-left" />
                                                                    </div>
                                                                </div>

                                                            </div>
                                                            <button type="submit" disabled={formik.isSubmitting} className='button-color primary-button '>Submit</button>
                                                        </Form>
                                                    )}
                                                </Formik>
                                            </div>
                                        }
                                    </div>
                                )
                            }
                        </div>
                    </div>
                </div>



            </div>
        </Fragment >
    )
}


export default Login;