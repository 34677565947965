import React from 'react'

export default function DynamicInput({ array, handleField, addField, removeField }) {

    return (
        <div className='dynamic-input'>
            {array.map((field, index) => (
                <div key={index}>
                    <div className='col-md-12 position-relative'>
                        <div className='d-flex form-group mt-2'>
                            <input
                                className='form-control'
                                type="text"
                                name='text'
                                placeholder={`Goal ${index + 1}`}
                                value={field.text}
                                onChange={(e) => handleField(index, e)}
                            />
                            {(array?.length === index + 1) &&
                                <button className='add-btn text-left'
                                    disabled={array[array.length - 1].title === ''}
                                    onClick={addField}><img src="./svg-icons/plus-circle.svg" alt='' />
                                </button>}
                        </div>
                        {
                            index === 0 ? null : <button
                                type="button"
                                onClick={() => removeField(index)}
                                className="remove-btn"
                            ><img src="./svg-icons/delete-icon.svg" alt='' /></button>
                        }
                    </div>
                </div>
            ))}

        </div>
    )
}
